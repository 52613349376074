$pure-white: #ffffff; // do not use unless contrasting with dusty-white
$white: #FDFDFC; //
$dusty-white: #F5F5F0; // dusty-white
$white-shade: #F2F2E7;
$white-shade-shade: #e0e0c6;
$teal-tint: #7fb5b1; // deep-teal
$teal: #115664; // deep-teal
$teal-shade:#042A32; // deep-teal
$hazy-sky-blue-tint: #e7eff8;
$hazy-sky-blue: #C0D4EC;
$hazy-sky-blue-shade: #99b9e0;
$powder-green-tint: #f4f9f5;
$powder-green: #DAEADD;
$electric-green: #8CFFA0;
$disable-gray: #D7D7D7;
$error-red: #FF5151; // red on teal red
$text-red: #D43A25; // red on white red
$hip-traffic-cone: #E5853B;
//red on white #EB0000

// the default is newfound theme
:root, [data-theme="newfound"]  {
  --color-text: #{$primary-shade};
  --color-pure-white: #{$pure-white};
  --color-white: #{$white};
  --color-white-shade: #{$white-shade};
  --color-call-to-action: #{$electric-green};
  --color-call-to-action-alt: #{$hip-traffic-cone};
  --color-primary-tint: #{$teal-tint};
  --color-primary: #{$teal};
  --color-primary-shade: #{$teal-shade};
  --color-secondary-1-tint: #{$powder-green-tint};
  --color-secondary-1: #{$powder-green};
  --color-secondary-1-shade: #{$powder-green};
  --color-secondary-2-tint: #{$hazy-sky-blue-tint};
  --color-secondary-2: #{$hazy-sky-blue};
  --color-secondary-2-shade: #{$hazy-sky-blue-shade};
  --color-secondary-3-tint: #{$dusty-white};
  --color-secondary-3: #{$white-shade};
  --color-secondary-3-shade: #{$white-shade-shade};
  --color-error: #{$error-red};
  --color-error-text: #{$text-red};
}

