$light-green: #99cc33;
$dark-green: #06492e;
$black: #000000;
$border-gray: #e9e9e9;
$bg-gray: #fafafa;
$sea-foam-green: #e4f8f0;
$dusty-blue: #0078ff;


// TODO: tint and shade variants for colors


[data-theme="trelora"]  {
  // --color-text: #{$primary-shade};
  // --color-pure-white: #{$pure-white};
  // --color-white: #{$white};
  // --color-white-shade: #{$white-shade};
  --color-call-to-action: #{$light-green};
  // --color-call-to-action-alt: #{$hip-traffic-cone};
  --color-primary-tint: #{$dark-green};
  --color-primary: #{$dark-green};
  --color-primary-shade: #{$dark-green};
  --color-secondary-1-tint: #{$sea-foam-green};
  --color-secondary-1: #{$sea-foam-green};
  --color-secondary-1-shade: #{$sea-foam-green};
  --color-secondary-2-tint: #{$dusty-blue};
  --color-secondary-2: #{$dusty-blue};
  --color-secondary-2-shade: #{$dusty-blue};
  // --color-secondary-3-tint: #{$dusty-white};
  // --color-secondary-3: #{$white-shade};
  // --color-secondary-3-shade: #{$white-shade-shade};
  // --color-error: #{$error-red};
  // --color-error-text: #{$text-red};
}
