// self hosted font
@font-face {
  font-family: 'noe-text';
  src: url('../assets/fonts/NoeText-Semibold.woff2') format('woff2'),
    url('../assets/fonts/NoeText-Semibold.woff') format('woff');
    // we have eot version of font but we dont support ie at all so left out
    // https://drive.google.com/file/d/1xgehi-j27LmtgHoHU8ASlJlEdrfcm_1y/view
}

$serif-font-family: "noe-text", serif;

// Fonts loaded in <head>
$sans-font-family: "neue-haas-grotesk-text", sans-serif;
$mono-font-family: "space-mono", mono;
