
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'src/styles/variables';

// Adhere
@mixin adhere {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin step-row {
  display: flex;
  height: 100%;
  margin: 0 22px;
  padding: 10px 0px;
  text-align: left;
  border-bottom: 1px solid gray(60);

  .step-col {
    padding-right: 10px;
  }

  .actions {
    // for the icons, timeline-field-indicators defines its own layout in addition/overlapping this
    flex: 0 0 56px; // important
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding-right: 0;
  }

  .label {
    position: relative;
    display: flex;
    flex: 3
  }

  .value {
    flex: 2;
    font-weight: normal;
  }

  .internal-only-indicator {
    display: none;

    @include respect-host-context('.internal-field') {
      position: absolute;
      top: 2px;
      left: 0;
      display: block;
      width: 210px; // larger hit area for hover
      transform: translateX(-16px);

      & img {
        height: 12px;
      }
    }
  }
}

@mixin underline-on-hover($color) {
  // can not have display: flex;
  color: $color;

  // only interact with direct child spans is probably what we want in most cases?
  > span:not(.no-underline) {
    @include transition(border-bottom-color);
    border-bottom: 1px solid transparent;
  }

  &:hover,
  &:focus {
    color: $color;
    text-decoration: none;

  > span:not(.no-underline) {
      border-bottom-color: $color;
    }
  }
}

@mixin underline-before-hover($color) {
  // can not have display: flex;
  color: $color;

  // only interact with direct child spans is probably what we want in most cases?
  > span:not(.no-underline) {
    @include transition(border-bottom-color);
    border-bottom: 1px solid $color;
  }

  &:hover,
  &:focus {
    color: $color;
    text-decoration: none;

    > span:not(.no-underline) {
      border-bottom-color: transparent;
    }
  }
}

@mixin tile($background-color: var(--color-secondary-3-tint)) {

  padding: {
    top: 14px;
    right: 22px;
    bottom: 14px;
    left: 22px;
  }

  border-radius: $border-radius;
  background-color: $background-color;
}

// Easing (material defaults)
$ease-default: cubic-bezier(0.4, 0, 0.2, 1);
$ease-accelerate: cubic-bezier(0.4, 0, 1, 1);
$ease-decelerate: cubic-bezier(0, 0, 0.2, 1);

// Duration
$duration-short: 200ms;
$duration-default: 500ms;
$duration-long: 750ms;


// Transition
@mixin transition($property,
  $duration: $duration-short,
  $timing-function: $ease-default) {
  transition: {
    property: $property;
    duration: $duration;
    timing-function: $timing-function;
  }
}

@mixin dash-layout() {
  display: block;
  margin-right: 15px;
  margin-left: 15px;

  @include media-breakpoint-up(sm) {
    margin-right: 20px;
    margin-left: 20px;
  }

  @include media-breakpoint-up(lg) {
    margin-right: 30px;
    margin-left: 30px;
  }

  @include media-breakpoint-up(content-max) {
    margin-right: auto;
    margin-left: auto;
    max-width: 1360px;
  }
}

@mixin bulleted-list {
  padding-left: 20px;
  list-style-type: disc;

  li {
    display: list-item;
  }
}

@mixin respect-host-context($host-context){
  :host-context(#{$host-context}) & {
    @content
  }
}

@mixin scale-on-hover($scale: 1.2) {
  @include transition(transform);

  &:focus,
  &:hover {
    transform: scale($scale);
  }
}
