// $secondary-blue-shade:#216397;
$primary-blue-tint: #004578; // og
$primary-blue: #103a65;
$primary-blue-shade: #0e2e52;
$secondary-blue-tint:#9ECEEB;
$secondary-blue:#3f9dd6;
$secondary-blue-shade: #307fb7;
$hip-traffic-cone: #E5853B;
$completed-green: #348360;
$light-gold-tint: #fcf6e3;
$light-gold: #F9EFCC;
$light-gold-shade: #f6e8b5;


[data-theme="houwzer"] {
  --color-text: #212121;
  --color-call-to-action: #{$secondary-blue-shade};
  --color-primary-tint: #{$primary-blue-tint};
  --color-primary: #{$primary-blue};
  --color-primary-shade: #{$primary-blue-shade};
  --color-secondary-1-tint: #{$secondary-blue-tint};
  --color-secondary-1: #{$secondary-blue};
  --color-secondary-1-shade: #{$secondary-blue-shade};
  --color-secondary-2-tint: #{$light-gold-tint};
  --color-secondary-2: #{$light-gold};
  --color-secondary-2-shade: #{$light-gold-shade};
}
