/*
July 18 2021 - Mikey:  The idea of this file is a place to put styling that directly overrides styles
in third party angular components. Its a work in progress, with some overrides still in styles-houwzer.scss
This is not a place for element/ bootstrap styling changes, but only for angular components where we
do not have direct access to the style sheet and thus need to bust the style scoping at a global level.
*/

@import 'ngx-bootstrap/datepicker/bs-datepicker.scss';
@import './typography';
// @import 'bootstrap/scss/functions';

/*
This is to override a fixed positioning mobile style that is causing
the date picker to render below the modal. That styling was done in an
attempt to center the datepicker on mobile. For now we'll just have to live
with absolute positioning, it seems fine.
https://github.com/valor-software/ngx-bootstrap/blob/c09f0f90925d4229ad2b08696fa50c7a5493c307/src/datepicker/bs-datepicker.scss#L513
*/

bs-datepicker-container,
bs-daterangepicker-container {
  position: absolute !important;
}

timepicker {
  button.btn-default {
    padding-top: 6px;
    padding-right: $btn-padding-x-sm;
    padding-bottom: 6px;
    padding-left: $btn-padding-x-sm;
  }
}

// make up for ngx-bootstrap's crappy styles

.well.bg-faded {
  background-color: transparent;
}

datepicker button, timepicker .btn {
  cursor: pointer;
}

/*
Hide the arrows of our date time picker. For some reason putting these styles in the date-time-picker
specific stylesheet is not working?
*/
.date-time-field-date-container {

  table {
    margin-top: 5px;
  }

  table tbody tr:first-child,
  table tbody tr:last-child {
    display: none;
  }

  button {
    border: 1px solid var(--color-text);
  }
}

// modified from node_modules/ngx-bootstrap/datepicker/bs-datepicker.scss
.bs-datepicker.datepicker-theme-override {
  background-color:  var(--color-white);
  border-radius: $border-radius;

  .bs-datepicker-head {
    background-color:  var(--color-primary);
    border-radius: $border-radius $border-radius 0 0 ;
    border: 0px solid transparent;
  }

  .bs-datepicker-body {
    background-color:  var(--color-secondary-3-tint);
    border-radius: 0 0 $border-radius $border-radius;
    border: 0px solid transparent;

    th {
      color: var(--color-text);
    }

    td {
      span {
        &.is-highlighted:not(.disabled):not(.selected) {
          background-color:  var(--color-secondary-1);
          color: var(--color-text);
        }

        &.active:not(.select-start):before,
        &.in-range:not(.select-start):before {
          background-color:  var(--color-secondary-1);
          color: var(--color-text);
        }

        &.is-highlighted.in-range:before,
        &.in-range.select-end:before {
          background: none;
          right: 0;
          left: 0;
        }

        &.selected {
          background-color:  var(--color-primary);
        }
      }
    }
  }
  }

ngx-bootstrap-multiselect {
  & .dropdown {
    display: block;
    width: 100%;

    @include media-breakpoint-up(sm) {
      display: inline-block;
      min-width: 155px;
    }

    & .btn {
      // TODO btn nested in dropdown bc contains reg buttons across the app,
      // but eventually  those btns should be wrapped in a different class and this can be de-nested for readability
      text-align: left;
      padding-right: 32px; //leave room for carat
      padding-left: 12px; //override newfound-styling
      overflow-x: hidden;

      &::before {
        // behind dropdown arrow. in front of overflowing text
        position: absolute;
        right: 1px;
        bottom: 50%;
        width: 32px;
        background-color:  var(--color-white);
        height: calc(100% - 4px);
        transform: translateY(50%);
        border-radius: $border-radius;
        content: "";
        transition: all 0.15s ease-in-out;
      }

      &:hover, &:focus {
        &::before {
          background-color:  var(--color-secondary-3);
        }
      }

      &:active {
        &::before {
          background-color:  var(--color-secondary-3);
        }
      }

      &::after {
        position: absolute;
        display: block;
        right: 16px;
        bottom: 50%;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid;
        transform: translateY(50%);
        content: '';
      }
    }
  }

  & .dropdown-menu {
    width: 100%;

    @include media-breakpoint-up(sm) {
      width: auto;
      min-width: 100%;
    }
  }

  & .dropdown-item {
    &:active, &.active{
      background-color: $teal;
    }
  }
}

/* Complete brute forcing of our app layering */
.simple-notification-wrapper { z-index: 10070 !important }
.loader-container { z-index: 10060 !important }
.swal2-container { z-index: 10052 !important }
bs-datepicker-container, bs-daterangepicker-container { z-index: 10051 !important } /* DatePicker does not have a high enough zindex to work in bootstrap modals (which I believe have a zindex of like 1050. I also believe this has since been fixed in 2.0.2 */
.modal { z-index: 10050 !important }
.pswp { z-index: 10050 !important } // <full-screen-carousel/> (PhotoSwipe)
.external-content-header { z-index: 10041 !important }
.mat-drawer { z-index: 10040 !important }
.mat-drawer-backdrop { z-index: 10030 !important }
.dash-mobile-header { z-index: 10020 !important }


/*
Bootstrap puts the modal overlay very high in the DOM (child of the body element) while
the actual modal itself is buried much deeper in the DOM where it's defined. If at any point
between those elements something defines a new stacking context, it becomes impossible to
get the model rendering above the overlay. For some reason this only occurs on actual iOS devices.
This hack removes the overlay itself and attempts to replicate it visually with a box shadow effect
*/
.modal-backdrop { display: none }
.modal-content {
  max-width: 800px;
  box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.5) ;


  @include media-breakpoint-down(md) {
    margin-top: $header-height;
  }
}

.modal-title {
    @include heading-6-text;
    font-weight: normal;
}


/*
Putting a row as the first child of the content body puts negative margin on the body
element, causing scrollbars to appear. This is fine here as long as we're always organizing
things in mat tab bodies by rows. If that changes, this style will have to become more specific
*/
/*
TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.
*/
.mat-tab-body-content {
  overflow: hidden !important;
}

/*
Hack Alert - Mat Tabs establish a new stacking context and are not allowing us to get
bootstrap modals within a tab above the sidebar. This moves the modal over to the right
in an attempt to 'center' it within the content window when the sidebar is displayed

Note: this is only an issue where we use the mat-tab-group component, user managment and settings
*/
.nested-tab-modal {
  @include media-breakpoint-up(lg) {
    padding-left: 180px
  }
}

/*
TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.
*/
.mat-tab-label {
    @include heading-6-text;
  font-size: 18px;
}

/*
TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.
*/
.mat-tab-body-content {
  padding-top: 20px;
}

.mat-mdc-paginator,
.mat-mdc-header-cell,
.mat-mdc-cell
{
    @include heading-6-text;
}

.mat-mdc-header-cell {
  font-size: 14px;
  font-weight: bold;
  color: var(--color-text);
}

/****** Dragula Styles **********/
// https://github.com/valor-software/ng2-dragula#3-add-the-css-to-your-project

/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

/* Various overrides to the default MatStepper styles. Mostly
things that reduce the amount of spacing and padding */
.complete-form {

  .mat-step-text-label {
    @include heading-6-text;
  }

  .mat-stepper-header {
    @include media-breakpoint-down(sm) {
      padding: 20px 10px;
      margin-bottom: 10px;
    }
  }

  /* So much padding and whitespace on mobile. This compresses things a bit */
  .mat-vertical-content-container {

    @include media-breakpoint-down(sm) {
      margin-left: 22px;
    }

    .mat-stepper-content {
      @include media-breakpoint-down(sm) {
        margin-left: 15px;
      }
    }

    .mat-vertical-content {
      @include media-breakpoint-down(sm) {
        padding: 0 12px 12px 4px;
      }
    }
  }

  .mat-step-icon {
    @include body-2-text(bold);
    width: 30px;
    height: 30px;
  }
}



/* Reducing the size of these elements to get them onto one line on mobile */
.listings-container .mat-mdc-paginator-container {
  @include media-breakpoint-down(xs) {
    font-size: .9em;

    .mat-mdc-paginator-range-label { margin: 0 }
  }
}

.action-alerts-container {

  td.mat-mdc-cell:first-of-type,
  td.mat-mdc-footer-cell:first-of-type,
  th.mat-mdc-header-cell:first-of-type {
    padding-left: 0;
  }
}

.simple-notification {
  border-radius: $border-radius;
}

.sn-content {
  padding: 0 !important;

  & div:last-child {
    padding-right: 20px;
  }
}

/* Google places autocomplete is an absolutely positioned element that
is appended to the bottom of the body, so scoped componet stylings have no effect
*/
.pac-container {
  border: 1px solid $secondary;

  // outline: none;
  &::after {
    display: none;
  }
}

.pac-item-selected {
  background-color: gray(10);

  &:hover,
  &:focus {
    background-color: gray(10);
  }
}

.pac-item {
  font-size: 14px;
  padding: 8px 12px;
}

.pac-item-query {
  font-size: 18px;
  margin-right: 6px;

  .pac-item-selected &,
  .pac-item:hover &,
  .pac-item:focus & {
   color: var(--color-primary-shade);;
  }
}

.step-description-tooltip {
  & .tooltip-inner {
    max-width: 75%;
    padding: 1rem 1.5rem;
  }
}

// Angular form validations
.ng-dirty.ng-invalid:not(:focus) {
  border-color: var(--color-error);
}


// sidenav content overlay mobile
.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0,0,0,.6);
}

// fix mat paginator select transparent background
.mat-mdc-select-panel {
  background-color: var(--color-white);
}
