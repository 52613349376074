/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
// our variable file needs to be imported before bootstrap to override bootstrap variable defaults
@import 'variables';
// main bootstrap import
@import 'bootstrap/scss/bootstrap';

// swiper
@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';

// 3rd party library imports
@import 'quill/dist/quill.snow.css';
@import 'photoswipe/dist/photoswipe.css';
@import 'photoswipe/dist/default-skin/default-skin.css';

// our global scss files
@import 'src/styles/themes/newfound';
@import 'src/styles/themes/houwzer';
@import 'src/styles/themes/trelora';
@import './library-overrides';
@import './styles-houwzer';
@import './typography';
@import './ctas';
@import './card';
@import './reset';

// known uses:
// - mat tabs
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

$md-dash: (
  50 : #115664,
  100 : #115664,
  200 : #115664,
  300 : #115664,
  400 : #115664,
  500 : #115664,
  600 : #115664,
  700 : #115664,
  800 : #115664,
  900 : #115664,
  A100 : #115664,
  A200 : #115664,
  A400 : #115664,
  A700 : #115664,
  contrast: (
      50 : #212121,
      100 : #212121,
      200 : #212121,
      300 : #ffffff,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #212121,
      A200 : #212121,
      A400 : #ffffff,
      A700 : #ffffff,
  )
);

html {
  min-height: 100vh;
}

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$dash-mat-primary: mat.define-palette($md-dash);
$dash-mat-secondary:  mat.define-palette($md-dash);

// Create the theme object (a Sass map containing all of the palettes).
$dash-mat-theme: mat.define-light-theme($dash-mat-primary, $dash-mat-secondary);

// Include the alternative theme styles inside of a block with a CSS class. You can make this
// CSS class whatever you want. In this example, any component inside of an element with
// `.unicorn-dark-theme` will be affected by this alternate dark theme instead of the default theme.

.dash-theme {
  @include mat.all-component-themes($dash-mat-theme);


  // obvious hack, trying to reset the default text color. add as needed or figure out mat-theme
  .mat-drawer-container,
  .mat-step-header .mat-step-label,
  .mat-step-header .mat-step-label.mat-step-label-active {
    color: var(--color-text);
  }
}

