// to get our theming colors to work with bootstrap,
// we need to override the actuall boostrap classes, not use the variables

.card-header {
  background-color: var(--color-secondary-3);
}

.card {
  background-color: var(--color-secondary-3-tint);
}
