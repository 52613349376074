@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
// @import 'bootstrap/scss/mixins/';
@import 'src/styles/variables';

// boostrap 4.6.
// https://github.com/twbs/bootstrap/blob/e5643aaa89eb67327a5b4abe7db976f0ea276b70/scss/mixins/_buttons.scss
// @include button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%)) {

@mixin disable-btn-styling {

  &.disabled,
  &:disabled {
    // color: color-yiq($background);
    background-color: $disable-gray;
    border-color: $disable-gray;
    pointer-events: none;
  }

  // leaving off styling for .dropdown-toggle see source if that is breaking anything
}

.btn {
  letter-spacing: 0.96px;
}

.btn-primary {
  //   @include button-variant($electric-green, $electric-green, $primary-shade, $primary-shade, $primary-shade, $primary-shade);
  //   @include disable-btn-styling;
  color: var(--color-text);

  [data-theme="houwzer"] & {
    color: var(--color-white);
  }

  border-color: var(--color-call-to-action);
  background-color: var(--color-call-to-action);
  // @include box-shadow($btn-box-shadow);

  &:focus,
  &:hover,
  &.focus {
    color: var(--color-white);
    border-color: var(--color-primary-shade);
    background-color: var(--color-primary-shade);
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 $btn-focus-width var(--color-secondary-1);
  }

  // Disabled comes first so active can properly restyle
  @include disable-btn-styling;

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: var(--color-white);
    background-color: var(--color-primary-shade);
    border-color: var(--color-primary-shade);

    &:focus {
      // unfortunately var does not work inside rgba
      box-shadow: 0 0 0 $btn-focus-width var(--color-secondary-1);
    }
  }
}


.btn-secondary {
  color: var(--color-text);
  border-color: var(--color-primary-shade);
  background-color: transparent;
  // @include box-shadow($btn-box-shadow);


  &:focus,
  &:hover,
  &.focus {
    color: var(--color-white);
    background-color: var(--color-primary-shade);
    ;
    border-color: var(--color-primary-shade);
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 $btn-focus-width var(--color-secondary-1);
  }

  @include disable-btn-styling;

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: var(--color-white);
    background-color: var(--color-primary-shade);
    border-color: var(--color-primary-shade);

    &:focus {
      box-shadow: 0 0 0 $btn-focus-width var(--color-secondary-1);
    }
  }
}

.btn-multiselect {
  color: var(--color-text);
  border-color: var(--color-primary-shade);
  background-color: var(--color-white);
  // @include box-shadow($btn-box-shadow);


  &:focus,
  &:hover,
  &.focus {
    color: var(--color-text);
    background-color: var(--color-secondary-3);
    border-color: var(--color-primary-shade);
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 $btn-focus-width var(--color-secondary-1);
  }

  @include disable-btn-styling;

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: var(--color-text);
    background-color: var(--color-secondary-3);
    border-color: var(--color-primary-shade);

    &:focus {
      box-shadow: 0 0 0 $btn-focus-width var(--color-secondary-1);
    }
  }
}

.btn-info {

  color: var(--color-white);


  color: var(--color-white);
  border-color: var(--color-primary-shade);
  background-color: var(--color-primary-shade);
  // @include box-shadow($btn-box-shadow);


  &:focus,
  &:hover,
  &.focus {
    color: var(--color-primary-shade);
    background-color:transparent;
    border-color: var(--color-primary-shade);
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 $btn-focus-width var(--color-secondary-1);
  }

  @include disable-btn-styling;

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: var(--color-primary-shade);
    background-color:transparent;
    border-color: var(--color-primary-shade);

    &:focus {
      box-shadow: 0 0 0 $btn-focus-width var(--color-secondary-1);
    }
  }

  // unfortunately bootstrap button calc not working for this button variant so defining the relevant parts explictly
  @include hover() {
    color: $primary-shade;
    border-color: $primary-shade;
  }

  &:focus,
  &.focus {
    color: $primary-shade;
    border-color: $primary-shade;
  }

  @include disable-btn-styling;
}
