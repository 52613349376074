@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'src/styles/fonts';

$hubspot-orange: #ff5c35;
$hubspot-mortgage-green: #0a6f0e;
$video-svg: #8328aa;
$completed-green: #348360;
$hip-traffic-cone: #E5853B;

$pure-white: #ffffff; // do not use unless contrasting with dusty-white
$white: #FDFDFC; //
$white-shade: #F2F2E7;

$teal: #115664; // deep-teal
$teal-shade:#042A32; // deep-teal
$hazy-sky-blue: #C0D4EC;
$powder-green: #DAEADD;
$electric-green: #8CFFA0;
$disable-gray: #D7D7D7;


$header-height: 55px;
$border-radius: 8px; //Bootstrap redifine

// generic re-definitions (we should do this for all colors for an easier brand transition)
$call-to-action: $hip-traffic-cone;

$primary: $teal;
$primary-shade: $teal-shade;
$secondary-1: $powder-green;
$secondary-2: $hazy-sky-blue;
$secondary-3: $white-shade;


$gray: #BDBDBD;
$gray-shades : (
  10: #F6F6F6,
  20: #F3F3F3,
  30: #EEEEEE,
  35: #dedede,
  40: $disable-gray,
  50: #CCCCCC,
  60: $gray,
  70: #9E9E9E,
  80: #757575,
  90: #616161,
  100: #424242,
  110: #212121,
);

// importing our variables file before bootstrap imports will break this
@function gray($shade : 60) {
  @return map-get($gray-shades, $shade);
}

$text: $teal-shade;

// bootstrap-overrides
$theme-colors: (
  "primary": $electric-green,
  "secondary": $white,
  "info": $teal, //?
  "warning": $call-to-action,
);

// note: .container defined differently with $container-max-widths
$grid-breakpoints: (xxs: 0,
  xs: 425px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  content-max: 1785px, //this is 1360px (dash-layout mixins max width) + width of sidebar + margins
);


/*
  chicken and egg scenario. this is here because
  we need to have these values defined before importing bootstrap variables in main.scss
  and then have ctas.scss be imported after, because we are then overriding boostrap classes in ctas.scss
*/

$input-btn-padding-y: 8px;
$input-btn-padding-x: 40px;
$input-btn-padding-y-sm: 8px;
$input-btn-padding-x-sm: 12px;
$input-btn-font-size: 14px;
$input-btn-line-height: 24px;
$input-btn-font-family: $sans-font-family;

$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-x-sm: $input-btn-padding-x-sm;
$btn-font-size: $input-btn-font-size;
$btn-line-height: $input-btn-line-height;
$btn-font-family: $input-btn-font-family;

$btn-border-radius: $border-radius;
// $btn-border-radius-lg: $btn-border-radius;
$btn-border-radius-sm: $btn-border-radius;

$input-border-radius: $border-radius;
$input-border-radius-lg: $border-radius;
$input-border-radius-sm: $border-radius;


$card-spacer-y:                     .75rem ;
$card-spacer-x:                     1.25rem;
$card-border-width:                 0;
$card-border-radius:                $border-radius;
$card-border-color:                 transparent;
$card-inner-border-radius:         $border-radius;
// $card-cap-bg: see _card.scss
// $card-bg:     see _card.scss



$progress-dot-size: 44px;
$progress-dot-row-height: 1.5 * $progress-dot-size;
$questionnaire-footer-height: $progress-dot-row-height + 35px;

// modal overrides
$modal-content-sm-up-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
$modal-md: 60%;
$close-color: $secondary;


// $input-color-placeholder: gray();
// $input-color: $secondary-blue;
$input-border-color: gray();
// $input-btn-border-width: 1px;

// $input-group-addon-bg: white;
// $input-group-addon-border-color: $quite-dark;
