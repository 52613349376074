@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'src/styles/variables';
@import 'src/styles/typography';
@import 'src/styles/mixins';

// this could be codefied in a better place
.form-control {
  border-color: var(--color-text)
}

.questionnaire-page-container hr {
  @extend .d-none;
  @extend .d-md-block;
}

hr {
  width: 100px;
  border-color: var(--color-white);

  margin: {
    top: 1.5em;
    bottom: 1.0em;
  }

  @include media-breakpoint-up(md) {
    margin: {
      top: 1.5em;
      bottom: 1.0em;
    }
  }
}

label {
  @include body-3-text(bold);
}


.progress-dot {
  display: inline-block;
  height: $progress-dot-size;
  width: $progress-dot-size;

  background-image: url('../assets/progress-dot/Complete.png');
  background-repeat: no-repeat;

  &.active {
    background-image: url('../assets/progress-dot/Active.png');
  }

  &.disabled {
    background-image: url('../assets/progress-dot/Inactive.png')
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.questionnaire-page-container {
  // this needs to have a defined height so the slide in of the animation will work.
  // perhaps moving the animation up would make this less painful
  @extend .container-fluid;
  position: relative;
  height: calc(100vh - #{$header-height} - #{$questionnaire-footer-height});
  overflow-y: auto;
  overflow-x: hidden;

  padding: {
    top: 1.5em;
    bottom: 50px;
  }

  @media (max-height: 300px) {
    height: 125vh;
  }
}

.questionnaire-page-inner-container {
  /*
small grid - 576 / 12 = 48px/col
md grid - 768 / 12 = 64px/col
lg grid - 992 / 12 = 82 px/col
xl grid - 1200/12 = 100px/col
width has to be set to a static value, or else animation is janky
*/
  margin-left: auto;
  margin-right: auto;
  width: 300px;

  @include media-breakpoint-up(xs) {
    width: 395px;
  }

  @include media-breakpoint-up(sm) {
    width: 550px;
  }
}

.progress-dot-row {
  display: flex;
  justify-content: center;
  align-items: center;
  // min-width: 100%;
  height: $progress-dot-row-height;

  background: {
    color: transparent;
  }

  z-index: 1100;

  .progress-dot {
    margin-left: 3px;
    margin-right: 3px;
  }
}

::placeholder {
  font-size: 0.9em;
  font-family: 'Open Sans', $font-family-base;
}


.input-group-addon {
  color: var(--color-text);
}

input[type=checkbox] {
  visibility: hidden;
}

input[type=checkbox]+span {

  &::before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: url('../assets/checkbox/Checkbox.png');
    background-size: cover;
    margin-left: -16px;
    margin-right: 4px;
    vertical-align: -10%;
  }
}

input[type=checkbox]:disabled+span {
  &::before {
    background-image: url('../assets/checkbox/CheckboxDisabled.png');
  }
}

input[type=checkbox]:checked+span {
  &::before {
    background-image: url('../assets/checkbox/CheckboxChecked.png');
  }
}

input[type=checkbox][disabled]:checked+span {
  &::before {
    background-image: url('../assets/checkbox/CheckboxCheckedDisabled.png');
  }
}


.badge {
  padding-right: .6em;
  padding-left: .6em;
  border-radius: 10rem;
  background-color: $secondary;
}

.input-group-btn {
  border: 1px solid gray(100);
}

.clear-input-btn {
  color: gray();

  &.hide {
    color: var(--color-white);
  }
}

a.link-button,
a.link-button:not([href]) {
  cursor: pointer;
  color: var(--color-primary-shade);
  ;
  font-weight: bold;

  &.action-item {
    color: $call-to-action;

    &:hover {
      color: darken($call-to-action, 7.5%)
    }

    &:active {
      color: darken($call-to-action, 15%)
    }
  }

  &:hover {
    color: darken($secondary, 7.5%)
  }

  &:active {
    color: darken($secondary, 15%)
  }
}

.timeline-type-label {
  min-width: 156px;
}

::placeholder {
  color: gray(70);
  font-style: italic;
}

table.table {
  th {
    @include body-2-text();
  }
}

svg.fa-file-pdf {
  color: var(--color-error-text);
}

svg.fa-file-image {
  color: $completed-green;
}

svg.fa-file-video {
  color: $video-svg;
}

.preview-body .ql-editor {
  background-color: gray(30);
}


.required-asterisk {
  margin: 0 2px;
  color: $call-to-action;
  font-weight: bold;
}

.subscription-modal {
  /*
  revert any font sizing that may be in play depending
  on where the modal is nested on the page
  */
  font-size: 1rem;

  hr {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;
    border-color: gray(20);
  }

  table.timeline-info {
    width: 100%;
    margin-bottom: 20px;
  }

  hr {
    margin: 30px 0;
  }
}

.invalid-message {
  color: var(--color-error-text) !important;
}

/*
Not using ng-invalid b/c date picker does not use it in the same way as
other input elements. Keying off of own rendered invalid class is consistent
regardless of what's going on with the input
*/
.complete-form-control.submitted.invalid {
  select {
    outline: 1px solid var(--color-error);
  }

  input,
  textarea,
  .input-group-addon {
    border-color: var(--color-error) !important;
    color: var(--color-error-text) !important;
  }
}


.confirmed-check {
  color: var(--color-primary-shade);
  ;
  margin-left: 5px;
}

// todo this would be nice to be a 'layout wrapper'
.suppressed-emails-message {
  color: var(--color-error-text);
  @include heading-4-text;
}

.suppressed-emails {
  margin-top: 10px;
  padding: 15px;
  border: 10px solid var(--color-error);
  margin-bottom: 20px;
}

.suppressed-emails-message-wrapper {
  @include dash-layout;
  padding-top: 20px;
}
