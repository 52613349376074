@import 'src/styles/variables';

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
// @mixin list-style-reset {
//   list-style-type: none;
//   margin: 0;
//   padding: 0;
// }

// @mixin button-reset {
//   // display: block;
//   // margin: 0;
//   // padding: 0;
//   // font-size: 1rem;
//   // color: var(--color-text);
//   // background: transparent;
//   // border-radius: none;
//   // border: 0px solid transparent;
//   // box-shadow: 0, 0, 0, 0 transparent;
// }

// svg,
img {
  display: block;
  max-width: 100%;
}

// Typography
ol,
ul {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none;
}

li {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

a {
  // color: currentColor;
  text-decoration: none;
  margin-top: 0;
  margin-bottom: 0;
  padding:0
}

button {
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  margin: 0;
  padding:0;
  background-color: transparent;
  border: 0;
  appearance: none;
}
