/*
July 18 2021 - Mikey: our element level styling of typography components should be done away with
in favor of named typographical styles, such as heading-primary, heading-secondary, body-copy, link-text
etc. heading elements should be used as semantic structuring, not styling.

In addition, the list of typographical mixins should be kept as short as possible.
Avoid creating a new type style "whenever we need it" and instead try to normalize / use continituity wherever possible
less than 20 should be possible, the closer to 10, the better
*/

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import './fonts';
@import './variables';

body {
  font-family: $sans-font-family;
  color: var(--color-text); //this currently does nothing. see bottom of main.scss for hack :(
}

$font-styles : (
  normal: normal,
  italic: italic,
);

$font-weights : (
  regular: 400,
  bold: 600,
);

$font-families : (
  serif: $serif-font-family,
  sans: $sans-font-family,
);

@function font($font) {
  @return map-get($font-families, $font);
}

@function font-style($style) {
  @return map-get($font-styles, $style);
}
@function font-weight($weight) {
  @return map-get($font-weights, $weight);
}

@mixin heading-1-text {
  //styleName: Mobile / headline / h1;
  font-family: $serif-font-family;
  font-size: 80px;
  font-style: normal;
  font-weight: 600;
  line-height: 84px;
  // letter-spacing: 1px;

  @include media-breakpoint-up(lg) {
    font-size: 112px;
    line-height: 116px;
    // letter-spacing: 1px;
  }
}

@mixin heading-2-text {
  //styleName: Mobile / headline / h2;
  font-family: $serif-font-family;
  font-size: 52px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -0.5px;

  @include media-breakpoint-up(lg) {
    font-size: 84px;
    line-height: 88px;
    letter-spacing: -0.75px;
  }
}

@mixin heading-3-text($font : serif) {
  //styleName: Mobile / headline / h3;
  font-family: font($font);
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.50px;

  @include media-breakpoint-up(lg) {
    //styleName: Desktop / headline / h3;
    font-size: 56px;
    line-height: 61px;
    letter-spacing: -0.50px;
  }
}

@mixin heading-4-text($font : serif) {
  font-family: font($font);
  //styleName: Mobile / headline / h4;

  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  // letter-spacing: 1px;

  @include media-breakpoint-up(lg) {
    //styleName: Desktop / headline / h4;
    font-size: 36px; // shrunk
    line-height: 40px;
  }
}

@mixin heading-5-text($font : sans) {
  font-family: font($font);
  //styleName: Mobile / headline / h5;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  // letter-spacing: 1px;

  @include media-breakpoint-up(lg) {
    //styleName: Desktop / headline / h5;
    font-size: 28px;
    line-height: 32px;
  }
}

@mixin heading-6-text($font: sans) {
  font-family: font($font);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  // letter-spacing: 1px
}

@mixin kicker-text {
  //styleName: Homebase UI / Headline / H2;
  font-family: $sans-font-family;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

@mixin eyebrow-text {
  //styleName: Mobile / eyebrow;
  font-family: $sans-font-family;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 2.5px;
  text-transform: uppercase;
}

@mixin image-caption-text {}

@mixin mono-text {
  font-family: $mono-font-family;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;

  @include media-breakpoint-up(lg) {
    font-size: 14px;
    line-height: 18px;
  }
}

@mixin body-1-text($style : normal, $weight: regular) {
  // styleName: Mobile / body / body 1 / regular;XZCxCZX  font-weight: 400;
  font-family: $sans-font-family;
  font-size: 18px;
  font-style: font-style($style);
  font-weight: font-weight($weight);
  line-height: 26px;
  letter-spacing: 0px;
  opacity: 1;

  @include media-breakpoint-up(lg) {
    //styleName: Desktop / body / body 1 / regular;
    font-size: 20px;
    line-height: 28px;
    // This is supposed to have opacity 0.75 on desktop, left off for now until CMS build
    // opacity: 0.75;
  }
}

@mixin body-2-text($weight : regular) {
  //styleName: Mobile / body / body 2: regular;

  font-family: $sans-font-family;
  font-size: 16px;
  font-style: normal;
  font-weight: font-weight($weight);
  line-height: 1.5;
  letter-spacing: 0px;
  opacity: 1;
}

@mixin body-3-text($weight : regular){
  //styleName: Mobile / body / body 2: regular;

  font-family: $sans-font-family;
  font-size: 14px;
  font-style: normal;
  font-weight: font-weight($weight);
  line-height: 1.3;
  letter-spacing: 0px;
}


@mixin body-4-text($weight : regular) {
  //styleName: Mobile / body / body 3;
  font-family: $sans-font-family;
  font-size: 12px;
  font-style: normal;
  font-weight: font-weight($weight);
  line-height: 1.5;
  letter-spacing: 0px;
  opacity: 1;
}

@mixin link-text($weight : regular) {
//styleName: CTA / text link;
  font-family: $sans-font-family;
  font-size: 14px;
  font-weight: $weight;
  line-height: 20px; //24px;
  letter-spacing: 0.36px;
}


@mixin info-card-header {
  font-family: $sans-font-family;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  line-height: 23px;
  text-align: left;
  opacity: 1;
  text-transform: uppercase;
}
